import React from 'react';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import RawHTML from '@components/Common/RawHTML/index';

const Card = ({ data }) => {
  const image = data.image ? getImage(data.image.childImageSharp) : null;
  const spanToWidth = data?.spanToWidth;

  return (
    <div
      className={`${
        spanToWidth ? 'w-full' : 'md:max-w-[48%]'
      } rounded-[20px] bg-v5-green-600 p-4  text-v5-neutral-300  ${
        data?.title?.includes('iTunes') ? 'sm:order-last' : ''
      } `}
    >
      {image && (
        <div className="mb-4 w-[90px]">
          <GatsbyImage
            placeholder="blurred"
            loading="lazy"
            image={image}
            alt={'Curriculum'}
            style={{ maxHeight: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      )}
      <h6 className="mb-2">{data.title}</h6>
      <div className="text-sm">
        <div>
          {data?.data?.map((ele, idx) => (
            <div key={idx}>
              <div className="mb-2 flex items-start gap-x-2">
                {data.data.length > 1 && (
                  <div className="w-[14px] min-w-[14px]">
                    <StaticImage
                      width={14}
                      loading="lazy"
                      src="../../../images/v5/ProgramCards/tick.png"
                      placeholder="none"
                      alt="Tick"
                      className="mt-1"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </div>
                )}
                <div>
                  <div className="leading-relaxed">
                    <RawHTML>{ele}</RawHTML>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          {data?.skills?.length > 0 && (
            <div className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3">
              {data?.skills?.map((ele, idx) => (
                <div
                  key={idx}
                  className="rounded-[5px] border-2 border-v5-neutral-300 p-1 px-3"
                >
                  <h6 className=" text-xs">{ele}</h6>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
