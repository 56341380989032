import React from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import { StaticImage } from 'gatsby-plugin-image';
import FspCarousel from '../FspCurriculumSection/FspCarousel/index';

const SkillSection = React.memo(function SkillSection(props) {
  console.log('🚀 ~ SkillSection ~ props:', props.dreamCompanies);
  return (
    <SectionLayout
      heading={
        <span className="text-v5-neutral-200">
          Master Data Insights with Key Analytics Tools
        </span>
      }
      description={
        <>
          Learn 15+ data analytics tools, including Git, Kafka, SQL, Excel, and
          Python
        </>
      }
      {...props}
    >
      <div className="container relative z-[999]">
        <FspCarousel data={props.dreamCompanies}></FspCarousel>
      </div>
    </SectionLayout>
  );
});

export default SkillSection;

let data = [
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointOne.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: (
      <>
        Learn by Working like<br></br>Tech Professionals
      </>
    ),
    description:
      'Learn Full Stack Development, Backend Development, QA Automation through an immersive project-based curriculum focused on practical developer skills and real-world scenarios.',
  },
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointTwo.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: (
      <>
        Gain Work-ex on Problems<br></br>Given by Real Tech Companies
      </>
    ),
    description:
      'Gain the work experience of building professional software products for India’s top tech companies like CRED, Playment, Jumbotail, Vicara, and others.',
    fellowship: true,
  },
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointThree.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: 'Learn From Seasoned Industry Professionals in Real Time',
    description:
      'Participate in activity-based live working sessions facilitated by industry mentors from companies like Microsoft, Flipkart, Nilenso.',
  },

  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointFour.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: (
      <>
        Get career support to break<br></br>through into ambitious roles
      </>
    ),
    description:
      'Be prepared for every interview and land exciting development jobs with structured planning and personalised guidance & support from Crio career coaches.',
  },
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointFive.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: (
      <>
        Build a GitHub portfolio<br></br> that impresses recruiters
      </>
    ),
    description:
      'Go beyond just certificates with a Crio verified project portfolio on GitHub and impress any recruiter at a product based company with your skills and experience.',
  },
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointSix.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: (
      <>
        Stand out at interviews <br></br>with real proof of work
      </>
    ),
    description:
      'Validate your learnings and let your skills shine with a work experience certificate that makes you stand out at interviews.',
  },
];
