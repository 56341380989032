import React from 'react';
import Plus from '../../../images/plus.svg';
import useResizer from '@components/extra/useResizer';
import { useLocation } from '@reach/router';
import CustomAccordion from './CustomAccordion';
import { cn } from '@/lib/utils';
import { useState } from 'react';

const MobileCurriculum = ({
  curriculumSection,
  secondCurriculum,
  curriculumSection3Ref,
  optionCurriculum,
  curriculumSection2Ref,
  isTypeQA,
  className,
}) => {
  const [opened, setOpened] = useState(false);

  const openedHandler = (anchor) => {
    setOpened(anchor);
  };

  //Monitoring elements within the viewport to record them for WebEngage events.
  const isMobile = useResizer();

  const location = useLocation();
  console.log('🚀 ~ location: optionCurriculum', curriculumSection?.anchor);

  return (
    <div
      className={cn(
        'flex w-full flex-wrap items-stretch gap-y-4  gap-x-4 text-v5-neutral-200',
        className,
      )}
    >
      {curriculumSection.map((ele, idx) => {
        return (
          <div className="w-full" key={idx} id={ele?.anchor}>
            <CustomAccordion
              data={ele}
              open={location.hash == '#' + ele?.anchor}
              anchor={ele.anchor}
              opened={opened}
              openedHandler={openedHandler}
            ></CustomAccordion>
          </div>
        );
      })}

      {!isTypeQA && optionCurriculum.length ? (
        <div className="flex w-full flex-wrap items-stretch gap-4 sm:flex-nowrap ">
          <div
            className=" w-full sm:w-[49%]"
            ref={isMobile ? curriculumSection2Ref : null}
          >
            <div ref={!isMobile ? curriculumSection2Ref : null}>
              <CustomAccordion
                data={optionCurriculum[0]}
                open={location.hash == '#' + optionCurriculum[0]?.anchor}
              ></CustomAccordion>
            </div>
            <div className="my-2 flex w-full justify-center">
              <Plus className="w-[20px] "></Plus>
            </div>
            <div>
              <CustomAccordion
                data={optionCurriculum[1]}
                open={location.hash == '#' + optionCurriculum[1]?.anchor}
              ></CustomAccordion>
            </div>
          </div>
          <div className="w-full sm:w-[49%]">
            <div>
              <CustomAccordion
                data={optionCurriculum[2]}
                open={location.hash == '#' + optionCurriculum[2]?.anchor}
              ></CustomAccordion>
            </div>
            <div className="my-2 flex w-full justify-center">
              <Plus className="w-[20px] "></Plus>
            </div>
            <div>
              <CustomAccordion
                data={optionCurriculum[3]}
                open={location.hash == '#' + optionCurriculum[3]?.anchor}
              ></CustomAccordion>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {!isTypeQA &&
        secondCurriculum.map((ele, idx) => {
          return (
            <div
              className="w-full"
              key={idx}
              id={ele?.anchor}
              ref={curriculumSection3Ref}
            >
              <CustomAccordion
                data={ele}
                open={location.hash == '#' + ele?.anchor}
              ></CustomAccordion>
            </div>
          );
        })}
    </div>
  );
};

export default MobileCurriculum;
