import Accordion from './Accordion';
import CurriculumHeader from './CurriculumHeader';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurriculumDetail from './CurriculumDetail';
import React, { useState } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { makeStyles } from '@mui/styles';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config.js';
import FAQCustomAccordion from './FAQCustomAccordion';

const fullConfig = resolveConfig(tailwindConfig);

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: '100%',
    height: props.isExpanded ? '100%' : 'auto',
    overflow: 'hidden',
    background: fullConfig.theme.colors['v5']['green']['700'],
    // border: props.isExpanded ? '1px solid white !important' : '',
  }),
  content: {
    margin: '8px 0px',
  },
}));

const CustomAccordion = ({
  open,
  data,
  gradient,
  variant,
  anchor,
  opened,
  openedHandler,
}) => {
  const [expanded, setExpanded] = useState(open || false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const classes = useStyles({ isExpanded: expanded });
  let cards = data?.cards || [];
  const image = getImage(data?.image);

  console.log('🚀 ~ CustomAccordion ~ image:', anchor, opened, expanded);

  function extractTextFromHTML(htmlString) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || '';
  }

  const handleChange = (event, isExpanded) => {
    if (isExpanded && !dropdownOpened) {
      const extractedTitle = extractTextFromHTML(data?.title);

      // Track GTM event
      GTM.track(gtmEvents.CURRICULLUM_DROPDOWN_CLICK, {
        curriculumSection: extractedTitle,
        action: 'open',
        type: 'FELLOWSHIP',
      });

      // Set dropdownOpened to true to avoid tracking multiple times
      setDropdownOpened(true);
      openedHandler(anchor);
    }

    openedHandler(anchor);
    setExpanded(isExpanded);
  };

  if (variant === 'FAQ') {
    return (
      <FAQCustomAccordion
        data={data}
        expanded={expanded && anchor === opened}
        handleChange={handleChange}
        classes={classes}
        image={image}
      />
    );
  }

  return data ? (
    <Accordion
      disableGutters
      expanded={opened === anchor}
      elevation={0}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit: true }}
      className={`rounded-[30px] ${
        gradient
          ? 'border-gradient-radius30 border-gradient bg-black bg-opacity-30'
          : 'bg-v5-green-700'
      }`}
    >
      <AccordionSummary
        className={`w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6`}
        expandIcon={
          <div className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300">
            <FontAwesomeIcon
              icon={['fas', 'chevron-right']}
              className={`w-[10px] rotate-90 transition-all`}
            ></FontAwesomeIcon>
          </div>
        }
        classes={{ content: classes.content }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <CurriculumHeader data={data} image={image} />
      </AccordionSummary>
      <AccordionDetails
        className={`${
          gradient ? '' : 'bg-v5-green-700'
        }  rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6`}
      >
        <CurriculumDetail data={data} />
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};

export default CustomAccordion;
