import React, { useContext, useEffect } from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import { useDialog } from '../Dialog/index';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { useCustomToast } from '@components/extra/useCustomToast';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { EButtonType } from '@src/constants/data/programs/index';
import { useInView } from 'react-intersection-observer';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';
import { cn } from '@/lib/utils';
import DesktopCurriculum from './DesktopCurriculum';
import MobileCurriculum from './MobileCurriculum';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_DOWNLOAD_CURRICULUM } from '@src/constants/LeadGenerationConstants/index';

const CurriculumV4 = React.memo(function CurriculumV4({
  type,
  curriculum,
  dreamCompanies,
  superDreamCompanies,
  advancedCurriculum,
  headingClassName,
  isTypeQA,
  isTypeFellowShipPlus,
  advancedCurriculumHeading,
  hideHeader,
  hideAdvanceCurriculumSection,
  ...props
}) {
  const [openDialog, closeDialog] = useDialog();
  const { isDataSciencePage } = useDataScienceProgram();

  //   const location = useLocation();
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const downloadCurriculumType = isTypeFellowShipPlus
    ? 'FELLOWSHIP_PLUS'
    : isTypeQA
    ? 'QA_AUTOMATION_TESTING'
    : isDataSciencePage
    ? 'DATA_ANALYTICS'
    : 'FELLOWSHIP';

  const triggerSuccessToast = useCustomToast(
    'success',
    <div className="">
      <h2 className="font-base font-rubik text-lg">Success!</h2>
      <p className="font-rubik text-xs">
        You will receive the curiculum in your email shortly.
        <br /> (In case you have your adblocker turned on, please write to us at{' '}
        <a href="mailto:programs@criodo.com" className="font-bold">
          programs@criodo.com
        </a>{' '}
        to receive the curriculum)
      </p>
    </div>,
  );

  //Monitoring elements within the viewport to record them for WebEngage events.
  //   const isMobile = useResizer();

  const [curriculumSection1Ref, inViewCurriculumSection1] = useInView({
    triggerOnce: true,
    threshold: 1.0,
  });
  const [curriculumSection2Ref, inViewCurriculumSection2] = useInView({
    triggerOnce: true,
    threshold: 1.0,
  });
  const [curriculumSection3Ref, inViewCurriculumSection3] = useInView({
    triggerOnce: true,
    threshold: 1.0,
  });

  // Consolidating into a single useEffect is avoided due to the concern
  // of pushing multiple instances of the same event into the dataLayer.

  useEffect(() => {
    if (inViewCurriculumSection1) {
      GTM.track(gtmEvents.SCROLL_TO_CURRICULUM_SECTION, {
        type: type,
        location: 'CURRICULLUM_SECTION_1',
      });
    }
  }, [inViewCurriculumSection1]);

  useEffect(() => {
    if (inViewCurriculumSection2) {
      GTM.track(gtmEvents.SCROLL_TO_CURRICULUM_SECTION, {
        type: type,
        location: 'CURRICULLUM_SECTION_2',
      });
    }
  }, [inViewCurriculumSection2]);

  useEffect(() => {
    if (inViewCurriculumSection3) {
      GTM.track(gtmEvents.SCROLL_TO_CURRICULUM_SECTION, {
        type: type,
        location: 'CURRICULLUM_SECTION_3',
      });
    }
  }, [inViewCurriculumSection3]);

  const triggerCurriculumWebengageFlow = (email, phone, type) => {
    GTM.login({
      userId: email,
      email: email,
      phone: phone,
      whatsappNumber: phone,
    });
    GTM.setEmail(email);

    if (phone) {
      GTM.track(gtmEvents.PHONE_SUBMITTED, {
        phone: phone,
        type: 'DOWNLOAD_CURRICULUM_STRIP',
      });
    }

    window.webengage &&
      window.webengage.user.setAttribute('we_whatsapp_opt_in', true);
    GTM.track(gtmEvents.EMAIL_SUBMITTED, {
      type: downloadCurriculumType,
      location: 'DOWNLOAD_CURRICULUM_STRIP',
    });

    GTM.track(gtmEvents.DOWNLOAD_CURRICULUM_APPLIED_SUCCESSFULLY, {
      type: downloadCurriculumType,
      location: 'STRIP',
    });

    if (type == EButtonType.DOWNLOAD_CURRICULUM_BUTTON) {
      GTM.leadSquaredEvent(
        ELeadSquaredActivityCode.SD_PAGE_DOWNLOAD_CURRICULUM_CLICKED,
        email,
      );
    } else {
      GTM.leadSquaredEvent(
        ELeadSquaredActivityCode.SD_PAGE_DOWNLOAD_CURRICULUM_SUBMITTED,
        email,
      );
    }

    triggerSuccessToast();
  };

  let firstCurriculum = [],
    optionCurriculum = [],
    secondCurriculum = [];

  for (let i = 0; i < curriculum?.length; i++) {
    if (curriculum[i]?.tag?.includes('OPTION_')) {
      optionCurriculum.push(curriculum[i]);
      continue;
    }
    if (optionCurriculum.length == 0) {
      firstCurriculum.push(curriculum[i]);
    } else {
      secondCurriculum.push(curriculum[i]);
    }
  }

  const curriculumSection = isTypeQA ? curriculum : firstCurriculum;
  // console.log('🚀 ~ curriculumSection:', curriculumSection);
  const dreamJobRange = isDataSciencePage
    ? '6 To 10 LPA'
    : props.dreamJobRange || '6 To 15 LPA';
  const superDreamJobRange = props.superDreamJobRange || '15 To 40 LPA';

  return (
    <SectionLayout
      headingClassName={headingClassName}
      heading={''}
      description={''}
      {...props}
    >
      <div className="container ">
        {!props.hideCurriculumSection && (
          <div
            className={cn(
              'rounded-[50px] bg-v5-green-500 p-4  text-white  sm:p-6 ',
              //   `${hideAdvanceCurriculumSection ? '' : 'mb-20'}`,
            )}
          >
            <h1 className="my-10 text-center font-manrope text-2xl font-extrabold capitalize leading-tight sm:text-4xl">
              Curriculum to crack dream jobs{' '}
              <br className="hidden sm:block"></br>({dreamJobRange}) in top
              Product-Based <br className="hidden sm:block"></br>companies
            </h1>
            <div className="mb-16" ref={curriculumSection1Ref}>
              {/* <FspCarousel data={dreamCompanies}></FspCarousel> */}
            </div>
            <div className="flex items-center justify-center">
              <DesktopCurriculum
                curriculumSection={curriculumSection}
                className="hidden"
              />
              <MobileCurriculum
                curriculumSection={curriculumSection}
                curriculumSection3Ref={curriculumSection3Ref}
                secondCurriculum={secondCurriculum}
                optionCurriculum={optionCurriculum}
                curriculumSection2Ref={curriculumSection2Ref}
                isTypeQA={isTypeQA}
                className="md:hidden"
              />
            </div>
          </div>
        )}
        <div className="mt-10 flex items-center justify-center">
          <LeadGenerationButton
            type="Download"
            text="Download Curriculum"
            isOnePageForm
            formHeading="Program Curriculum"
            pageOneButtonText="Download Now"
            className={
              'border-white text-white hover:border-v5-green-700 hover:bg-white hover:text-v5-green-700'
            }
            buttonLocation={LEAD_GEN_DOWNLOAD_CURRICULUM}
            onSubmitPageOne={triggerCurriculumWebengageFlow}
            {...(state.email &&
              state.phone && {
                onClick: () => {
                  triggerCurriculumWebengageFlow(
                    state.email,
                    state.phone,
                    EButtonType.DOWNLOAD_CURRICULUM_BUTTON,
                  );
                },
              })}
          ></LeadGenerationButton>
        </div>
      </div>
    </SectionLayout>
  );
});

export default CurriculumV4;
