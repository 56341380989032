import React from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import FspCarousel from '../FspCurriculumSection/FspCarousel/index';

const CompanySection = React.memo(function CompanySection(props) {
  return (
    <SectionLayout
      heading={
        <span className="text-v5-neutral-200">
          Immersive Program to Land Top Analytics Jobs At The Fastest-Growing
          Companies
        </span>
      }
      description={
        <>
          Master the in-demand Data Analytics skills through our AI-integrated
          hands-on, project-based fellowship program
        </>
      }
      {...props}
    >
      <div className="container relative z-[999]">
        <FspCarousel data={props.dreamCompanies}></FspCarousel>
      </div>
    </SectionLayout>
  );
});

export default CompanySection;
