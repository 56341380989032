import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import RawHTML from '@components/Common/RawHTML/index';
import Card from './Card';

const CurriculumDetail = ({ data }) => {
  console.log('🚀 ~ CurriculumDetail ~ data:', data);
  return (
    <>
      <div className="md:pl-[54px]">
        {data.description && (
          <div className="mb-4 text-v5-neutral-200">{data.description}</div>
        )}
        <div className="">
          {data.data.map((ele, idx) => (
            <div key={idx}>
              <div className="mb-2 flex items-start gap-x-2">
                {data.data.length > 1 && (
                  <div className="w-[18px] min-w-[18px]">
                    <StaticImage
                      width={18}
                      loading="lazy"
                      src="../../../images/v5/ProgramCards/tick.png"
                      placeholder="none"
                      alt="Tick"
                      className="mt-1"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </div>
                )}
                <div>
                  <p>
                    <RawHTML>{ele}</RawHTML>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {data?.skills?.length > 0 && (
          <div className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3">
            {data?.skills?.map((ele, idx) => (
              <div
                key={idx}
                className="rounded-[5px] border-2 border-v5-neutral-300 p-1 px-3"
              >
                <h6 className=" text-xs">{ele}</h6>
              </div>
            ))}
          </div>
        )}
        {data?.projects?.length > 0 && (
          <div className=" mt-3">
            <h6 className="mb-4 text-v5-neutral-200">
              {data.projects.length > 1 && data.projects.length} Work-like
              Professional Projects
            </h6>
            <div className="text-sm">
              <ul className="m-0 pl-6">
                {data?.projects?.map((ele, idx) => (
                  <li key={idx} className="mb-2">
                    <h6 className=" text-sm">
                      {ele.title ? ele.title + ' -' : ''}{' '}
                      <span className="font-normal">{ele.description}</span>
                    </h6>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {data?.miniProjects?.length > 0 && (
          <div className=" mt-3">
            <h6 className="mb-2 text-v5-neutral-200">
              {data.miniProjects.length > 1 && data.miniProjects.length}{' '}
              Mini-projects
            </h6>
            <div className="text-sm">
              <ul className="m-0 pl-6">
                {data?.miniProjects?.map((ele, idx) => (
                  <li key={idx} className="mb-2">
                    <h6 className=" text-sm">
                      {ele.title ? ele.title + ' -' : ''}{' '}
                      <span className="font-normal">{ele.description}</span>
                    </h6>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {data?.workshops?.length > 0 && (
          <div className=" mt-3">
            <h6 className="mb-4 text-v5-neutral-200">
              {data.workshops.length > 1 && data.workshops.length} Hands-on
              Workshop
            </h6>
            <div className="text-sm">
              <ul className="m-0 pl-6">
                {data?.workshops?.map((ele, idx) => (
                  <li key={idx} className="mb-2">
                    <p>
                      <RawHTML>{ele}</RawHTML>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      {data?.cards?.length > 0 && (
        <>
          <div className="md:pl-[54px]">
            <hr className="my-3 border-b border-crio-green-400 opacity-20" />
            <h6 className="font-manrope text-white">{data.cardTitle}</h6>

            <div className="my-4 flex flex-wrap items-stretch gap-5 ">
              {data.cards.map(
                (ele, idx) => ele?.data !== null && <Card data={ele}></Card>,
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CurriculumDetail;
